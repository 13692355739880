var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-6 px-10 w-full lg:h-screen overflow-hidden" },
    [_c("goals", { attrs: { token: _vm.queryParam.token } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }